











































































import { Component, Prop, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import { ValmistumispyyntoListItem, Page } from '@/types'
import { ValmistumispyynnonTila } from '@/utils/constants'
import { ValmistumispyynnonHyvaksyjaRole } from '@/utils/roles'

@Component({
  components: {
    ElsaButton,
    ElsaPagination
  }
})
export default class ValmistumispyynnotList extends Vue {
  @Prop({ required: true, default: undefined })
  valmistumispyynnot!: Page<ValmistumispyyntoListItem>

  @Prop({ required: true, type: Boolean, default: false })
  hakutermiExists!: boolean

  @Prop({ required: true, type: Number })
  currentPage!: number

  @Prop({ required: true, type: Number })
  perPage!: number

  @Prop({ required: true, type: Boolean, default: false })
  loading!: boolean

  fields = [
    {
      key: 'erikoistujanNimi',
      label: this.$t('erikoistuja'),
      class: 'nimi',
      sortable: false
    },
    {
      key: 'tila',
      label: this.$t('tila'),
      class: 'tila',
      sortable: false
    },
    {
      key: 'tapahtumanAjankohta',
      label: this.$t('pvm'),
      class: 'pvm',
      sortable: false
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      class: 'actions'
    }
  ]

  odottaaOsaamisenArviointia(valmistumispyynto: ValmistumispyyntoListItem) {
    return valmistumispyynto.tila === ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_TARKASTUSTA
  }

  odottaaHyvaksyntaa(valmistumispyynto: ValmistumispyyntoListItem) {
    return valmistumispyynto.tila === ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
  }

  linkComponent(valmistumispyynto: ValmistumispyyntoListItem) {
    switch (valmistumispyynto.rooli) {
      case ValmistumispyynnonHyvaksyjaRole.VIRKAILIJA:
        return 'valmistumispyynnon-tarkistus'
      case ValmistumispyynnonHyvaksyjaRole.VASTUUHENKILO_OSAAMISEN_ARVIOIJA_HYVAKSYJA:
        switch (valmistumispyynto.tila) {
          case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_TARKASTUSTA:
          case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
          case ValmistumispyynnonTila.VASTUUHENKILON_TARKASTUS_PALAUTETTU:
          case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_PALAUTETTU:
            return 'valmistumispyynnon-arviointi'
          default:
            return 'valmistumispyynnon-hyvaksynta'
        }
      case ValmistumispyynnonHyvaksyjaRole.VASTUUHENKILO_OSAAMISEN_ARVIOIJA:
        return 'valmistumispyynnon-arviointi'
      case ValmistumispyynnonHyvaksyjaRole.VASTUUHENKILO_HYVAKSYJA:
        return 'valmistumispyynnon-hyvaksynta'
    }
  }

  getActionButtonText(valmistumispyynto: ValmistumispyyntoListItem) {
    if (valmistumispyynto.tila === ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_TARKASTUSTA) {
      return this.$t('tee-arviointi')
    } else if (
      valmistumispyynto.tila === ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
    ) {
      return this.$t('hyvaksy')
    } else {
      return this.$t('tarkista')
    }
  }

  taskIcon(status: string, isAvoin: boolean) {
    if (isAvoin) {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_TARKASTUSTA:
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        case ValmistumispyynnonTila.ODOTTAA_ALLEKIRJOITUKSIA:
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
          return ['far', 'clock']
      }
    } else {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
        case ValmistumispyynnonTila.ODOTTAA_ALLEKIRJOITUKSIA:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
          return ['far', 'check-circle']
        case ValmistumispyynnonTila.VASTUUHENKILON_TARKASTUS_PALAUTETTU:
        case ValmistumispyynnonTila.VASTUUHENKILON_HYVAKSYNTA_PALAUTETTU:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_PALAUTETTU:
          return ['fas', 'undo-alt']
        case ValmistumispyynnonTila.ALLEKIRJOITETTU:
          return ['fas', 'check-circle']
      }
    }
  }

  taskClass(status: string, isAvoin: boolean) {
    if (isAvoin) {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_TARKASTUSTA:
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
        case ValmistumispyynnonTila.ODOTTAA_ALLEKIRJOITUKSIA:
          return 'text-warning'
      }
    } else {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
        case ValmistumispyynnonTila.ODOTTAA_ALLEKIRJOITUKSIA:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
        case ValmistumispyynnonTila.ALLEKIRJOITETTU:
          return 'text-success'
      }
    }
    return ''
  }

  taskStatus(status: string, isAvoin: boolean) {
    if (isAvoin) {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_TARKASTUSTA:
          return this.$t('valmistumispyynnon-tila.avoin-odottaa-osaamisen-arviointia')
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
          return this.$t('valmistumispyynnon-tila.avoin-odottaa-hyvaksyntaa')
        case ValmistumispyynnonTila.ODOTTAA_ALLEKIRJOITUKSIA:
          return this.$t('valmistumispyynnon-tila.avoin-odottaa-allekirjoituksia')
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
          return this.$t('valmistumispyynnon-tila.avoin-odottaa-virkailijan-tarkastusta')
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
          return this.$t('valmistumispyynnon-tila.avoin-virkailijan-tarkastus-kesken')
      }
    } else {
      switch (status) {
        case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
          return this.$t('valmistumispyynnon-tila.valmis-odottaa-virkailijan-tarkastusta')
        case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
          return this.$t('valmistumispyynnon-tila.valmis-odottaa-hyvaksyntaa')
        case ValmistumispyynnonTila.ODOTTAA_ALLEKIRJOITUKSIA:
          return this.$t('valmistumispyynnon-tila.valmis-odottaa-allekirjoituksia')
        case ValmistumispyynnonTila.VASTUUHENKILON_TARKASTUS_PALAUTETTU:
        case ValmistumispyynnonTila.VASTUUHENKILON_HYVAKSYNTA_PALAUTETTU:
        case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_PALAUTETTU:
          return this.$t('valmistumispyynnon-tila.palautettu-erikoistujalle')
        case ValmistumispyynnonTila.ALLEKIRJOITETTU:
          return this.$t('valmistumispyynnon-tila.allekirjoitettu')
      }
    }
  }

  odottaaAllekirjoituksia(valmistumispyynto: ValmistumispyyntoListItem) {
    return valmistumispyynto.tila === ValmistumispyynnonTila.ODOTTAA_ALLEKIRJOITUKSIA
  }

  get content() {
    return this.valmistumispyynnot?.content
  }

  get rows() {
    return this.valmistumispyynnot?.page.totalElements ?? 0
  }

  onPageInput(value: number) {
    this.$emit('update:currentPage', value)
  }
}
