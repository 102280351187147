import { render, staticRenderFns } from "./arviointi-form.vue?vue&type=template&id=0b5a2cbc&scoped=true&"
import script from "./arviointi-form.vue?vue&type=script&lang=ts&"
export * from "./arviointi-form.vue?vue&type=script&lang=ts&"
import style0 from "./arviointi-form.vue?vue&type=style&index=0&id=0b5a2cbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b5a2cbc",
  null
  
)

export default component.exports