import { render, staticRenderFns } from "./yek-koulutettavien-seuranta-card.vue?vue&type=template&id=30d22540&scoped=true&"
import script from "./yek-koulutettavien-seuranta-card.vue?vue&type=script&lang=ts&"
export * from "./yek-koulutettavien-seuranta-card.vue?vue&type=script&lang=ts&"
import style0 from "./yek-koulutettavien-seuranta-card.vue?vue&type=style&index=0&id=30d22540&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d22540",
  null
  
)

export default component.exports