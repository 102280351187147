










































































































































































































































































































































































































































































































































import axios from 'axios'
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { Validation, validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'

import ElsaArviointiasteikonTasoTooltipContent from '@/components/arviointiasteikon-taso/arviointiasteikon-taso-tooltip.vue'
import ElsaArviointiasteikonTaso from '@/components/arviointiasteikon-taso/arviointiasteikon-taso.vue'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import AsiakirjatUpload from '@/components/asiakirjat/asiakirjat-upload.vue'
import ElsaBadge from '@/components/badge/badge.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import ElsaVaativuustasoTooltipContent from '@/components/vaativuustaso/vaativuustaso-tooltip-content.vue'
import ElsaVaativuustaso from '@/components/vaativuustaso/vaativuustaso.vue'
import store from '@/store'
import {
  ArviointiasteikonTaso,
  Arviointityokalu,
  Asiakirja,
  Suoritusarviointi,
  SuoritusarviointiForm,
  Vaativuustaso
} from '@/types'
import { resolveRolePath } from '@/utils/apiRolePathResolver'
import {
  ArvioinninPerustuminen,
  ArviointiasteikkoTyyppi,
  vaativuustasot
} from '@/utils/constants'
import { mapFile } from '@/utils/fileMapper'
import { sortByAsc } from '@/utils/sort'

@Component({
  components: {
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    UserAvatar,
    ElsaArviointiasteikonTaso,
    ElsaBadge,
    ElsaPopover,
    ElsaButton,
    ElsaVaativuustaso,
    AsiakirjatUpload,
    AsiakirjatContent,
    ElsaVaativuustasoTooltipContent,
    ElsaArviointiasteikonTasoTooltipContent
  },
  validations: {
    form: {
      arvioitavatKokonaisuudet: {
        $each: {
          arviointiasteikonTaso: {
            required
          }
        }
      },
      sanallinenArviointi: {
        required
      },
      arviointiPerustuu: {
        required: requiredIf((value) => {
          return value.perustuuMuuhun === true
        })
      },
      muuPeruste: {
        required: requiredIf((value) => {
          return value.arviointiPerustuu === ArvioinninPerustuminen.MUU
        })
      }
    }
  }
})
export default class ArviointiForm extends Mixins(validationMixin) {
  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({ required: true, type: Object })
  value!: Suoritusarviointi

  @Prop({ required: false, default: false })
  itsearviointi!: boolean

  // Joko arviointi tai itsearviointi
  form: SuoritusarviointiForm = {
    vaativuustaso: null,
    arvioitavatKokonaisuudet: null,
    sanallinenArviointi: null,
    arviointityokalut: [],
    arviointiPerustuu: null,
    muuPeruste: null,
    perustuuMuuhun: false
  }
  vaativuustasot = vaativuustasot
  arviointiasteikonTasot: ArviointiasteikonTaso[] = []
  arviointityokalut: Arviointityokalu[] = []
  addedFiles: File[] = []
  newAsiakirjatMapped: Asiakirja[] = []
  deletedAsiakirjat: Asiakirja[] = []
  params = {
    saving: false
  }
  arviointiperusteet = [
    {
      text: this.$t('arviointi-perustuu-kirjallinen'),
      value: ArvioinninPerustuminen.KIRJALLINEN
    },
    {
      text: this.$t('arviointi-perustuu-etayhteys'),
      value: ArvioinninPerustuminen.ETA
    },
    {
      text: this.$t('arviointi-perustuu-muu'),
      value: ArvioinninPerustuminen.MUU
    }
  ]

  async mounted() {
    this.arviointiasteikonTasot = this.value.arviointiasteikko.tasot
    if (this.itsearviointi) {
      this.form = {
        vaativuustaso: vaativuustasot.find(
          (taso) => taso.arvo === this.value.itsearviointiVaativuustaso
        ),
        arvioitavatKokonaisuudet: this.value.arvioitavatKokonaisuudet.map((k) => {
          return {
            ...k,
            arviointiasteikonTaso: this.arviointiasteikonTasot.find(
              (asteikonTaso) => asteikonTaso.taso === k.itsearviointiArviointiasteikonTaso
            )
          }
        }),
        sanallinenArviointi: this.value.sanallinenItsearviointi
      }
    } else {
      this.form = {
        ...this.value,
        vaativuustaso: vaativuustasot.find((taso) => taso.arvo === this.value.vaativuustaso),
        arvioitavatKokonaisuudet: this.value.arvioitavatKokonaisuudet.map((k) => {
          return {
            ...k,
            arviointiasteikonTaso: this.arviointiasteikonTasot.find(
              (asteikonTaso) => asteikonTaso.taso === k.arviointiasteikonTaso
            )
          }
        }),
        sanallinenArviointi: this.value.sanallinenArviointi,
        arviointityokalut: this.value.arviointityokalut,
        arviointiPerustuu:
          this.value.arviointiPerustuu === ArvioinninPerustuminen.LASNA
            ? null
            : this.value.arviointiPerustuu,
        muuPeruste: this.value.muuPeruste,
        perustuuMuuhun:
          this.value.arviointiPerustuu !== null &&
          this.value.arviointiPerustuu !== ArvioinninPerustuminen.LASNA
      }
      this.arviointityokalut = (
        (await axios.get(`/arviointityokalut`)).data as Arviointityokalu[]
      )?.sort((a, b) => {
        if (a.nimi === 'Muu') {
          return 1
        } else {
          return sortByAsc(a.nimi, b.nimi)
        }
      })
    }
  }

  get account() {
    return store.getters['auth/account']
  }

  get muuValittu() {
    return this.form.arviointiPerustuu === ArvioinninPerustuminen.MUU
  }

  get arviointiAsteikonNimi() {
    return this.value.arviointiasteikko.nimi === ArviointiasteikkoTyyppi.EPA
      ? this.$t('luottamuksen-taso')
      : this.$t('etappi')
  }

  get asiakirjatTableItems() {
    return (
      this.itsearviointi ? this.value.itsearviointiAsiakirjat : this.value.arviointiAsiakirjat
    )
      .filter((a) => !this.deletedAsiakirjat.includes(a))
      .concat(this.newAsiakirjatMapped)
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateArvioitavaKokonaisuusState(index: number) {
    const { $dirty, $error } = this.$v.form?.arvioitavatKokonaisuudet?.$each[index]
      ?.arviointiasteikonTaso as Validation
    return $dirty ? ($error ? false : null) : null
  }

  onArviointiFileAdded(files: File[]) {
    const file = files[0]
    this.newAsiakirjatMapped.push(mapFile(file))
    this.addedFiles.push(file)
  }

  onArviointiFileDeleted(asiakirja: Asiakirja) {
    if (asiakirja.id) {
      this.deletedAsiakirjat = [asiakirja, ...this.deletedAsiakirjat]
    } else {
      this.addedFiles = this.addedFiles?.filter((file) => file.name !== asiakirja.nimi)
      this.newAsiakirjatMapped = this.newAsiakirjatMapped?.filter(
        (a) => a.nimi !== asiakirja.nimi
      )
    }
  }

  onSubmit() {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) {
      return
    }
    if (this.itsearviointi) {
      const submitData = {
        suoritusarviointi: {
          ...this.value,
          arvioitavatKokonaisuudet: this.form.arvioitavatKokonaisuudet?.map((k) => {
            return {
              ...k,
              itsearviointiArviointiasteikonTaso: (
                k.arviointiasteikonTaso as ArviointiasteikonTaso
              ).taso,
              arviointiasteikonTaso: null
            }
          }),
          itsearviointiVaativuustaso: this.form.vaativuustaso?.arvo,
          sanallinenItsearviointi: this.form.sanallinenArviointi,
          arviointiasteikko: null,
          arviointiAsiakirjat: null,
          itsearviointiAsiakirjat: null
        },
        addedFiles: this.addedFiles,
        deletedAsiakirjaIds: this.deletedAsiakirjat.map((asiakirja) => asiakirja.id)
      }

      this.$emit('submit', submitData, this.params)
    } else {
      const submitData = {
        suoritusarviointi: {
          ...this.value,
          arvioitavatKokonaisuudet: this.form.arvioitavatKokonaisuudet?.map((k) => {
            return {
              ...k,
              arviointiasteikonTaso: (k.arviointiasteikonTaso as ArviointiasteikonTaso).taso,
              itsearviointiArviointiasteikonTaso: null
            }
          }),
          vaativuustaso: this.form.vaativuustaso?.arvo,
          sanallinenArviointi: this.form.sanallinenArviointi,
          arviointityokalut: this.form.arviointityokalut,
          arviointiPerustuu: this.form.perustuuMuuhun
            ? this.form.arviointiPerustuu
            : ArvioinninPerustuminen.LASNA,
          muuPeruste: this.muuValittu ? this.form.muuPeruste : null,
          arviointiAsiakirjat: null,
          itsearviointiAsiakirjat: null,
          arviointiasteikko: null
        },
        addedFiles: this.addedFiles,
        deletedAsiakirjaIds: this.deletedAsiakirjat.map((asiakirja) => asiakirja.id)
      }

      this.$emit('submit', submitData, this.params)
    }
  }

  get asiakirjaDataEndpointUrl() {
    return this.value.id
      ? `${resolveRolePath()}/suoritusarvioinnit/${this.value.id}/arviointi-liite`
      : ''
  }

  get linkki() {
    return `<a
              href="https://www.laaketieteelliset.fi/ammatillinen-jatkokoulutus/elsa"
              target="_blank"
              rel="noopener noreferrer"
            >${this.$t('arviointi-liitetiedostot-kuvaus-linkki')}</a>`
  }

  arviointiasteikonTasoLabel(value: ArviointiasteikonTaso) {
    return `${value.taso} ${this.$t('arviointiasteikon-taso-' + value.nimi)}`
  }

  vaativuustasoLabel(value: Vaativuustaso) {
    return `${value.arvo} ${this.$t(value.nimi)}`
  }

  muuPerustePituus() {
    return this.form.muuPeruste != null ? this.form.muuPeruste.length : 0
  }
}
