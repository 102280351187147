var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arviointityokalu"},[_c('b-breadcrumb',{staticClass:"mb-0",attrs:{"items":_vm.items}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"lg":""}},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('arviointityokalu')))]),_c('hr'),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"label":_vm.$t('tila')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [(_vm.form.tila.toLowerCase() === 'julkaistu')?[_c('span',{staticClass:"text-success",attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.$t('arviointityokalu-tila-' + _vm.form.tila.toLowerCase()))+" ")])]:[_vm._v(" "+_vm._s(_vm.$t('arviointityokalu-tila-' + _vm.form.tila.toLowerCase()))+" ")]]}}])})],1),_c('hr'),(_vm.form)?_c('div',[_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"label":_vm.$t('arviointityokalun-nimi')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(_vm._s(_vm.form.nimi))])]}}],null,false,1183714576)})],1),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"label":_vm.$t('kategoria')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.form.kategoria ? _vm.form.kategoria.nimi : _vm.$t('ei-kategoriaa'))+" ")])]}}],null,false,1593839227)})],1),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-12 pr-md-3",attrs:{"label":_vm.$t('ohjeteksti-arviointityokalun-kayttoon')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uid = ref.uid;
return [_c('span',{attrs:{"id":uid}},[_vm._v(" "+_vm._s(_vm.form.ohjeteksti)+" ")])]}}],null,false,3207931823)})],1),_c('b-form-row',[_c('elsa-form-group',{staticClass:"col-sm-12 col-md-6 pr-md-3",attrs:{"label":_vm.$t('arviointityokalu-liitetiedostona')}},[(_vm.asiakirjat && _vm.asiakirjat.length > 0)?_c('asiakirjat-content',{attrs:{"asiakirjat":_vm.asiakirjat,"sorting-enabled":false,"pagination-enabled":false,"enable-search":false,"show-info-if-empty":false,"enable-delete":false}}):_c('b-alert',{attrs:{"variant":"dark","show":""}},[_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"info-circle","fixed-width":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('ei-liitetiedostoa'))+" ")])],1)],1)],1),_c('hr'),_c('h1',[_vm._v(_vm._s(_vm.$t('kysymykset')))]),_vm._l((_vm.form.kysymykset),function(kysymys,index){return _c('div',{key:kysymys.jarjestysnumero,staticClass:"mt-4"},[_c('arviointityokalu-lomake-kysymys-form',{attrs:{"kysymys":kysymys,"child-data-received":!_vm.loading,"answer-mode":false,"index":index}})],1)}),_c('hr'),_c('div',{staticClass:"d-flex flex-row-reverse flex-wrap"},[_c('elsa-button',{staticClass:"mb-3 ml-3",attrs:{"variant":"primary","to":{
                name: 'lisaa-arviointityokalu',
                params: { arviointityokaluId: _vm.form.id }
              }}},[_vm._v(" "+_vm._s(_vm.$t('muokkaa-arviointityokalua'))+" ")]),(_vm.form.id)?_c('elsa-button',{staticClass:"mb-3 ml-3",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.showDeleteConfirm()}}},[_vm._v(" "+_vm._s(_vm.$t('poista-arviointityokalu'))+" ")]):_vm._e(),_c('elsa-button',{staticClass:"mb-3 mr-auto font-weight-500 kayttajahallinta-link",attrs:{"to":{ name: 'arviointityokalut' },"variant":"link"}},[_vm._v(" "+_vm._s(_vm.$t('palaa-arviointityokaluihin'))+" ")])],1)],2):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('ladataan')}})],1)],1)],1),_c('elsa-confirmation-modal',{attrs:{"id":"confirm-dialog","title":_vm.$t('arviointityokalu-poista-arviointityokalu'),"submit-text":_vm.$t('arviointityokalu-poista-arviointityokalu'),"text":_vm.$t('arviointityokalu-poista-arviointityokalu-selite'),"submit-variant":"outline-danger","hide-on-submit":false},on:{"submit":_vm.onArviointityokaluDelete,"cancel":_vm.onCancelConfirm},scopedSlots:_vm._u([{key:"modal-content",fn:function(){return undefined},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }