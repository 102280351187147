











































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import {
  getErikoistujienSeuranta as getErikoistujienSeurantaKouluttaja,
  getErikoistujienSeurantaKouluttajaRajaimet
} from '@/api/kouluttaja'
import {
  getErikoistujienSeuranta as getErikoistujienSeurantaVastuuhenkilo,
  getErikoistujienSeurantaVastuuhenkiloRajaimet
} from '@/api/vastuuhenkilo'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaProgressBar from '@/components/progress-bar/progress-bar.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import ErikoistujienSeurantaMixin from '@/mixins/erikoistujien-seuranta'
import {
  ErikoistujanEteneminen,
  ErikoistujienSeurantaVastuuhenkiloRajaimet,
  Page,
  SortByEnum
} from '@/types'
import { ErikoistuvanSeurantaJarjestys } from '@/utils/constants'
import { getKeskiarvoFormatted } from '@/utils/keskiarvoFormatter'
import { sortByAsc } from '@/utils/sort'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaPagination,
    ElsaProgressBar,
    ElsaSearchInput
  }
})
export default class ErikoistujienSeurantaCard extends Mixins(ErikoistujienSeurantaMixin) {
  rajaimet: ErikoistujienSeurantaVastuuhenkiloRajaimet | null = null
  erikoistujat: Page<ErikoistujanEteneminen> | null = null

  @Prop({ required: false, default: false })
  showKouluttajaKuvaus!: boolean

  sortFields: SortByEnum[] = [
    {
      name: this.$t('opintooikeus-paattymassa'),
      value: ErikoistuvanSeurantaJarjestys.OPINTOOIKEUS_PAATTYMASSA
    } as SortByEnum,
    {
      name: this.$t('opintooikeus-alkaen'),
      value: ErikoistuvanSeurantaJarjestys.OPINTOOIKEUS_ALKAEN
    } as SortByEnum,
    // {
    //   name: this.$t('tyoskentelyaikaa-vahiten'),
    //   value: ErikoistuvanSeurantaJarjestys.TYOSKENTELYAIKAA_VAHITEN
    // } as SortByEnum,
    // {
    //   name: this.$t('tyoskentelyaikaa-eniten'),
    //   value: ErikoistuvanSeurantaJarjestys.TYOSKENTELYAIKAA_ENITEN
    // } as SortByEnum,
    {
      name: this.$t('sukunimi-a-o'),
      value: ErikoistuvanSeurantaJarjestys.SUKUNIMI_ASC
    } as SortByEnum,
    {
      name: this.$t('sukunimi-o-a'),
      value: ErikoistuvanSeurantaJarjestys.SUKUNIMI_DESC
    } as SortByEnum
  ]
  sortBy = this.sortFields[0]

  filtered: {
    nimi: string | null
    naytaPaattyneet: boolean | null
    sortBy: string | null
  } = {
    nimi: null,
    naytaPaattyneet: null,
    sortBy: null
  }
  currentPage = 1
  perPage = 20
  debounce?: number

  hakutermi = ''
  loading = true

  async mounted() {
    try {
      await Promise.all([this.fetchRajaimet(), this.fetch()])
    } catch {
      toastFail(this, this.$t('erikoistujien-seurannan-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  async fetchRajaimet() {
    this.rajaimet = this.$isVastuuhenkilo()
      ? (await getErikoistujienSeurantaVastuuhenkiloRajaimet()).data
      : (await getErikoistujienSeurantaKouluttajaRajaimet()).data
  }

  async fetch() {
    try {
      this.erikoistujat = this.$isVastuuhenkilo()
        ? (
            await getErikoistujienSeurantaVastuuhenkilo({
              page: this.currentPage - 1,
              size: this.perPage,
              sort: this.filtered.sortBy ?? 'opintooikeudenPaattymispaiva,asc',
              ...(this.filtered.nimi ? { 'nimi.contains': this.filtered.nimi } : {}),
              ...(this.filtered.naytaPaattyneet
                ? { naytaPaattyneet: this.filtered.naytaPaattyneet }
                : {})
            })
          ).data
        : (
            await getErikoistujienSeurantaKouluttaja({
              page: this.currentPage - 1,
              size: this.perPage,
              sort: this.filtered.sortBy ?? 'opintooikeudenPaattymispaiva,asc',
              ...(this.filtered.nimi ? { 'nimi.contains': this.filtered.nimi } : {}),
              ...(this.filtered.naytaPaattyneet
                ? { naytaPaattyneet: this.filtered.naytaPaattyneet }
                : {})
            })
          ).data
    } catch (err) {
      toastFail(this, this.$t('erikoistujien-seurannan-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  get seurantaTitle() {
    if (this.rajaimet == null) {
      return ''
    }
    let result = ''
    this.rajaimet.kayttajaYliopistoErikoisalat.forEach((kayttajaErikoisala) => {
      result +=
        this.$t(`yliopisto-nimi.${kayttajaErikoisala.yliopistoNimi}`) +
        ': ' +
        kayttajaErikoisala.erikoisalat.join(', ') +
        '. '
    })
    return result
  }

  async onNaytaPaattyneetSelect() {
    await this.onResultsFiltered()
  }

  async onSortBySelect(sortByEnum: SortByEnum) {
    switch (sortByEnum.value) {
      case ErikoistuvanSeurantaJarjestys.OPINTOOIKEUS_PAATTYMASSA:
        this.filtered.sortBy = 'opintooikeudenPaattymispaiva,asc'
        break
      case ErikoistuvanSeurantaJarjestys.OPINTOOIKEUS_ALKAEN:
        this.filtered.sortBy = 'opintooikeudenMyontamispaiva,asc'
        break
      case ErikoistuvanSeurantaJarjestys.TYOSKENTELYAIKAA_VAHITEN:
        this.filtered.sortBy = 'tyoskentelyaikaYhteensa,asc'
        break
      case ErikoistuvanSeurantaJarjestys.TYOSKENTELYAIKAA_ENITEN:
        this.filtered.sortBy = 'tyoskentelyaikaYhteensa,desc'
        break
      case ErikoistuvanSeurantaJarjestys.SUKUNIMI_ASC:
        this.filtered.sortBy = 'erikoistuvaLaakari.kayttaja.user.lastName,asc'
        break
      case ErikoistuvanSeurantaJarjestys.SUKUNIMI_DESC:
        this.filtered.sortBy = 'erikoistuvaLaakari.kayttaja.user.lastName,desc'
        break
    }
    this.onResultsFiltered()
  }

  get erikoisalatSorted() {
    return this.rajaimet?.erikoisalat.sort((a, b) => sortByAsc(a, b))
  }

  onPageInput(value: number) {
    this.currentPage = value
    this.fetch()
  }

  private async onResultsFiltered() {
    this.loading = true
    this.currentPage = 1
    await this.fetch()
    this.loading = false
  }

  get rows() {
    return this.erikoistujat?.page.totalElements ?? 0
  }

  keskiarvoFormatted(keskiarvo: number) {
    return getKeskiarvoFormatted(keskiarvo)
  }

  @Watch('hakutermi')
  onPropertyChanged(value: string) {
    this.debounceSearch(value)
  }

  debounceSearch(value: string) {
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      this.filtered.nimi = value
      this.onResultsFiltered()
    }, 400)
  }
}
