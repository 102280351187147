












































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import { getTerveyskeskuskoulutusjaksot as getTerveyskeskuskoulutusjaksotVastuuhenkilo } from '@/api/vastuuhenkilo'
import { getTerveyskeskuskoulutusjaksot as getTerveyskeskuskoulutusjaksotVirkailija } from '@/api/virkailija'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import { Erikoisala, Page, TerveyskeskuskoulutusjaksonVaihe } from '@/types'
import {
  ERIKOISALA_YEK_ID,
  LomakeTilat,
  TaskStatus,
  TerveyskeskuskoulutusjaksonTila
} from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaSearchInput,
    ElsaPagination,
    ElsaButton,
    ElsaFormGroup,
    ElsaFormMultiselect
  }
})
export default class TerveyskeskuskoulutusjaksotYek extends Vue {
  avoimetJaksot: Page<TerveyskeskuskoulutusjaksonVaihe> | null = null
  muutJaksot: Page<TerveyskeskuskoulutusjaksonVaihe> | null = null
  hakutermi = ''

  fields = [
    {
      key: 'erikoistuvanNimi',
      label: this.$t('yek.koulutettava'),
      class: 'nimi',
      sortable: true
    },
    {
      key: 'tila',
      label: this.$t('tila'),
      class: 'tila',
      sortable: true
    },
    {
      key: 'pvm',
      label: this.$t('pvm'),
      class: 'pvm',
      sortable: true
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      class: 'actions'
    }
  ]

  filtered: {
    nimi: string | null
    erikoisala: Erikoisala | null
    sortBy: string | null
  } = {
    nimi: null,
    erikoisala: null,
    sortBy: null
  }
  currentAvoinPage = 1
  currentMuutPage = 1
  perPage = 10
  debounce?: number
  loading = true

  async mounted() {
    try {
      await this.fetch()
    } catch {
      toastFail(this, this.$t('terveyskeskuskoulutusjaksojen-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  async fetch() {
    await this.fetchAvoimet()
    await this.fetchMuut()
  }

  async fetchAvoimet() {
    const avoinParams = {
      page: this.currentAvoinPage - 1,
      size: this.perPage,
      sort: this.filtered.sortBy ?? 'muokkauspaiva,asc',
      ...(this.filtered.nimi ? { 'erikoistujanNimi.contains': this.filtered.nimi } : {}),
      ...{ 'erikoisalaId.equals': ERIKOISALA_YEK_ID },
      avoin: true
    }
    this.avoimetJaksot = (
      await (this.$isVirkailija()
        ? getTerveyskeskuskoulutusjaksotVirkailija(avoinParams)
        : getTerveyskeskuskoulutusjaksotVastuuhenkilo(avoinParams))
    ).data
  }

  async fetchMuut() {
    const muutParams = {
      page: this.currentMuutPage - 1,
      size: this.perPage,
      sort: this.filtered.sortBy ?? 'muokkauspaiva,desc',
      ...(this.filtered.nimi ? { 'nimi.contains': this.filtered.nimi } : {}),
      ...{ 'erikoisalaId.equals': ERIKOISALA_YEK_ID },
      avoin: false
    }
    this.muutJaksot = (
      await (this.$isVirkailija()
        ? getTerveyskeskuskoulutusjaksotVirkailija(muutParams)
        : getTerveyskeskuskoulutusjaksotVastuuhenkilo(muutParams))
    ).data
  }

  @Watch('hakutermi')
  onPropertyChanged(value: string) {
    this.debounceSearch(value)
  }

  debounceSearch(value: string) {
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      this.filtered.nimi = value
      this.onResultsFiltered()
    }, 400)
  }

  async onAvoinPageInput(value: number) {
    this.currentAvoinPage = value
    await this.fetchAvoimet()
  }

  onMuutPageInput(value: number) {
    this.currentMuutPage = value
    this.fetchMuut()
  }

  private async onResultsFiltered() {
    this.loading = true
    this.currentAvoinPage = 1
    this.currentMuutPage = 1
    await this.fetch()
    this.loading = false
  }

  get avoimetRows() {
    return this.avoimetJaksot?.page.totalElements ?? 0
  }

  get muutRows() {
    return this.muutJaksot?.page.totalElements ?? 0
  }

  taskIcon(status: string) {
    switch (status) {
      case TerveyskeskuskoulutusjaksonTila.ODOTTAA_VIRKAILIJAN_TARKISTUSTA:
        return ['far', 'clock']
      case TerveyskeskuskoulutusjaksonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        return this.$isVirkailija() ? ['far', 'check-circle'] : ['far', 'clock']
      case TerveyskeskuskoulutusjaksonTila.PALAUTETTU_KORJATTAVAKSI:
        return ['fas', 'undo-alt']
      case LomakeTilat.HYVAKSYTTY:
        return ['fas', 'check-circle']
    }
  }

  taskClass(status: string) {
    switch (status) {
      case TerveyskeskuskoulutusjaksonTila.ODOTTAA_VIRKAILIJAN_TARKISTUSTA:
        return 'text-warning'
      case TerveyskeskuskoulutusjaksonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        return this.$isVirkailija() ? 'text-success' : 'text-warning'
      case LomakeTilat.PALAUTETTU_KORJATTAVAKSI:
        return ''
      case LomakeTilat.HYVAKSYTTY:
        return 'text-success'
    }
  }

  taskStatus(status: string) {
    switch (status) {
      case TerveyskeskuskoulutusjaksonTila.ODOTTAA_VIRKAILIJAN_TARKISTUSTA:
        return this.$t('lomake-tila-' + TaskStatus.AVOIN)
      case TerveyskeskuskoulutusjaksonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        return this.$isVirkailija()
          ? this.$t('lomake-tila-' + TaskStatus.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA)
          : this.$t('lomake-tila-' + TaskStatus.AVOIN)
      case TerveyskeskuskoulutusjaksonTila.PALAUTETTU_KORJATTAVAKSI:
        return this.$t('lomake-tila-' + TaskStatus.PALAUTETTU)
      case TerveyskeskuskoulutusjaksonTila.HYVAKSYTTY:
        return this.$t('lomake-tila-' + TaskStatus.HYVAKSYTTY)
    }
  }
}
