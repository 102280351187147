







































































































import Component from 'vue-class-component'
import { Vue, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPoissaolonSyyt from '@/components/poissaolon-syyt/poissaolon-syyt.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import { ArviointityokaluKysymys } from '@/types'
import { ArviointityokaluKysymysTyyppi } from '@/utils/constants'

@Component({
  components: {
    ElsaPoissaolonSyyt,
    ElsaButton,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    ElsaFormDatepicker,
    ElsaPopover
  },
  validations: {
    kysymys: {
      otsikko: required
    }
  }
})
export default class ArviointityokaluLomakeKysymysLuontiForm extends Vue {
  @Prop({ type: Object, required: true })
  kysymys!: ArviointityokaluKysymys | any

  @Prop({ type: Boolean, default: false })
  childDataReceived!: boolean

  @Prop({ required: true, type: Number })
  index?: number

  validateState(name: string) {
    const { $dirty, $error } = this.kysymys[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  onAddVastausVaihtoehto() {
    this.kysymys.vaihtoehdot.push({
      teksti: '',
      valittu: false
    })
  }

  deleteVastausVaihtoehto(index: number) {
    const vaihtoehdot = [...this.kysymys.vaihtoehdot]
    vaihtoehdot.splice(index, 1)
    this.kysymys.vaihtoehdot = vaihtoehdot
    this.$emit('skipRouteExitConfirm', false)
  }

  mounted() {
    this.$parent.$on('validate-all-kysymykset', this.validateForm)
  }

  beforeDestroy() {
    this.$parent.$off('validate-all-kysymykset', this.validateForm)
  }

  validateForm(): boolean {
    this.$v.poissaolo.$touch()
    return !this.$v.$anyError
  }

  onDeleteKysymys() {
    this.$emit('delete', this.index)
  }

  get arviointityokaluKysymysTyyppit() {
    return ArviointityokaluKysymysTyyppi
  }
}
