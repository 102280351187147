























































































import { Component, Mixins, Watch } from 'vue-property-decorator'

import { getVirkailijat } from '@/api/kayttajahallinta'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import KayttajahallintaListMixin from '@/mixins/kayttajahallinta-list'
import { SortByEnum, YliopistoErikoisalaPair } from '@/types'
import { KayttajaJarjestys } from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaPagination,
    ElsaSearchInput
  }
})
export default class Virkailijat extends Mixins(KayttajahallintaListMixin) {
  fields = [
    {
      key: 'nimi',
      label: this.$t('nimi'),
      sortable: false
    },
    {
      key: 'yliopisto',
      label: this.$t('yliopisto'),
      sortable: false
    },
    {
      key: 'tila',
      label: this.$t('tilin-tila'),
      sortable: false
    }
  ]

  async mounted() {
    this.loading = true
    try {
      await this.fetch()
    } catch {
      toastFail(this, this.$t('kayttajien-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  async fetch() {
    this.kayttajat = (
      await getVirkailijat({
        page: this.currentPage - 1,
        size: this.perPage,
        sort: this.filtered.sortBy ?? 'user.lastName,asc',
        ...(this.filtered.nimi ? { 'nimi.contains': this.filtered.nimi } : {})
      })
    ).data
  }

  @Watch('hakutermi')
  onPropertyChanged(value: string) {
    this.debounceSearch(value)
  }

  onPageInput(value: number) {
    this.currentPage = value
    this.fetch()
  }

  onSortBySelect(sortByEnum: SortByEnum) {
    switch (sortByEnum.value) {
      case KayttajaJarjestys.SUKUNIMI_ASC:
        this.filtered.sortBy = 'user.lastName,asc'
        break
      case KayttajaJarjestys.SUKUNIMI_DESC:
        this.filtered.sortBy = 'user.lastName,desc'
        break
    }
    this.filterResults()
  }

  debounceSearch(value: string) {
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      this.filtered.nimi = value
      this.filterResults()
    }, 400)
  }

  async filterResults() {
    this.loading = true
    this.currentPage = 1
    await this.fetch()
    this.loading = false
  }

  getYliopisto(yliopistotAndErikoisalat: YliopistoErikoisalaPair[]) {
    return yliopistotAndErikoisalat[0]
      ? this.$t(`yliopisto-nimi.${yliopistotAndErikoisalat[0].yliopisto}`)
      : ''
  }

  get rows() {
    return this.kayttajat?.page.totalElements ?? 0
  }
}
