














































import { AxiosError } from 'axios'
import { Component, Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import { postArviointityokalutKategoria } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ErikoistuvaLaakariForm from '@/forms/uusi-erikoistuva-laakari-form.vue'
import PaakayttajaForm from '@/forms/uusi-paakayttaja-form.vue'
import VastuuhenkiloForm from '@/forms/uusi-vastuuhenkilo-form.vue'
import VirkailijaForm from '@/forms/uusi-virkailija-form.vue'
import { ArviointityokaluKategoria, ElsaError } from '@/types'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ElsaFormError,
    ElsaFormGroup,
    ErikoistuvaLaakariForm,
    VastuuhenkiloForm,
    VirkailijaForm,
    PaakayttajaForm,
    ElsaButton
  },
  validations: {
    form: {
      nimi: {
        required
      }
    }
  }
})
export default class UusiKategoria extends Mixins(validationMixin) {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('arviointityokalut'),
      to: { name: 'arviointityokalut' }
    },
    {
      text: this.$t('lisaa-uusi-kategoria'),
      active: true
    }
  ]

  form: ArviointityokaluKategoria = {
    nimi: null
  }

  saving = false

  async onSubmit() {
    const validations = [this.validateForm()]
    if (validations.includes(false)) {
      return
    }

    try {
      await postArviointityokalutKategoria({
        ...this.form
      })
      toastSuccess(this, this.$t('arviointityokalu-kategoria-lisatty'))
      this.$emit('skipRouteExitConfirm', true)
      this.$router.push({
        name: 'arviointityokalut'
      })
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('arviointityokalu-kategoria-lisaaminen-epaonnistui')}: ${this.$t(message)}`
          : this.$t('arviointityokalu-kategoria-lisaaminen-epaonnistui')
      )
    }
    this.saving = false
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form?.[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  mounted() {
    if (this.$isTekninenPaakayttaja()) {
      /* */
    }
  }

  onCancel() {
    this.$router.push({
      name: 'arviointityokalut'
    })
  }

  skipRouteExitConfirm(value: boolean) {
    this.$emit('skipRouteExitConfirm', value)
  }
}
