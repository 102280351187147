






















































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import store from '@/store'
import { ELSA_ROLE } from '@/utils/roles'

@Component
export default class SidebarMenu extends Vue {
  paddingTop = 64
  sideNavSubItemHeight = 38
  sidebarPosition = 'position-fixed'
  featurePreviewModeEnabled = process.env.VUE_APP_FEATURE_PREVIEW_MODE_ENABLED === 'true'

  // Tarkistetaan sivunavigaation paikka
  mounted() {
    const el = document.getElementById('navbar-top')
    if (el) {
      this.paddingTop = el.offsetHeight
    }
    this.adjustSidebarPosition()
  }

  get account() {
    return store.getters['auth/account']
  }

  created() {
    window.addEventListener('resize', this.adjustSidebarPosition)
  }

  destroyed() {
    window.removeEventListener('resize', this.adjustSidebarPosition)
  }

  adjustSidebarPosition() {
    const footer = document.querySelector('footer')
    const sideNavItems = Array.from(document.querySelectorAll('#sidebar-menu li'))
    const navItemsTotalHeight = sideNavItems
      .map((el: Element) => el.clientHeight)
      .reduce(
        (a: number, b: number) =>
          (a != 0 ? a : this.sideNavSubItemHeight) + (b != 0 ? b : this.sideNavSubItemHeight)
      )

    if (
      footer &&
      window.innerHeight <= navItemsTotalHeight + footer.clientHeight + this.paddingTop
    ) {
      this.sidebarPosition = 'position-absolute'
    } else {
      this.sidebarPosition = 'position-fixed'
    }
  }

  get isImpersonated() {
    return this.account?.impersonated
  }

  get isImpersonatedErikoistujaVirkailija() {
    return (
      (this.account?.impersonated &&
        this.account.originalUser.authorities.includes(ELSA_ROLE.OpintohallinnonVirkailija)) ??
      false
    )
  }
}
