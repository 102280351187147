



























































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import { getErikoisalat, getKoejaksot } from '@/api/virkailija'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import { Erikoisala, KoejaksonVaihe, Page } from '@/types'
import { LomakeTilat, TaskStatus } from '@/utils/constants'
import { sortByAsc } from '@/utils/sort'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaSearchInput,
    ElsaPagination,
    ElsaButton,
    ElsaFormGroup,
    ElsaFormMultiselect
  }
})
export default class KoejaksoViewVirkailija extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('koejakso'),
      active: true
    }
  ]

  avoimetKoejaksot: Page<KoejaksonVaihe> | null = null
  muutKoejaksot: Page<KoejaksonVaihe> | null = null
  hakutermi = ''
  erikoisalat: Erikoisala[] | null = null

  fields = [
    {
      key: 'erikoistuvanNimi',
      label: this.$t('erikoistuja'),
      class: 'nimi',
      sortable: true
    },
    {
      key: 'tila',
      label: this.$t('tila'),
      class: 'tila',
      sortable: true
    },
    {
      key: 'pvm',
      label: this.$t('pvm'),
      class: 'pvm',
      sortable: true
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
      class: 'actions'
    }
  ]

  filtered: {
    nimi: string | null
    erikoisala: Erikoisala | null
    sortBy: string | null
  } = {
    nimi: null,
    erikoisala: null,
    sortBy: null
  }
  currentAvoinPage = 1
  currentMuutPage = 1
  perPage = 10
  debounce?: number
  loading = true

  async mounted() {
    try {
      await Promise.all([this.fetchRajaimet(), this.fetch()])
    } catch {
      toastFail(this, this.$t('koejaksojen-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  async fetchRajaimet() {
    this.erikoisalat = (await getErikoisalat()).data
  }

  async fetch() {
    await this.fetchAvoimet()
    await this.fetchMuut()
  }

  async fetchAvoimet() {
    this.avoimetKoejaksot = (
      await getKoejaksot({
        page: this.currentAvoinPage - 1,
        size: this.perPage,
        sort: this.filtered.sortBy ?? 'muokkauspaiva,asc',
        ...(this.filtered.nimi ? { 'erikoistujanNimi.contains': this.filtered.nimi } : {}),
        ...(this.filtered.erikoisala?.id
          ? { 'erikoisalaId.equals': this.filtered.erikoisala.id }
          : {}),
        avoin: true
      })
    ).data
  }

  async fetchMuut() {
    this.muutKoejaksot = (
      await getKoejaksot({
        page: this.currentMuutPage - 1,
        size: this.perPage,
        sort: this.filtered.sortBy ?? 'muokkauspaiva,desc',
        ...(this.filtered.nimi ? { 'nimi.contains': this.filtered.nimi } : {}),
        ...(this.filtered.erikoisala?.id
          ? { 'erikoisalaId.equals': this.filtered.erikoisala.id }
          : {}),
        avoin: false
      })
    ).data
  }

  @Watch('hakutermi')
  onPropertyChanged(value: string) {
    this.debounceSearch(value)
  }

  debounceSearch(value: string) {
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      this.filtered.nimi = value
      this.onResultsFiltered()
    }, 400)
  }

  onAvoinPageInput(value: number) {
    this.currentAvoinPage = value
    this.fetchAvoimet()
  }

  onMuutPageInput(value: number) {
    this.currentMuutPage = value
    this.fetchMuut()
  }

  onErikoisalaSelect(erikoisala: Erikoisala) {
    this.filtered.erikoisala = erikoisala
    this.onResultsFiltered()
  }

  onErikoisalaReset() {
    this.filtered.erikoisala = null
    this.onResultsFiltered()
  }

  private async onResultsFiltered() {
    this.loading = true
    this.currentAvoinPage = 1
    this.currentMuutPage = 1
    await this.fetch()
    this.loading = false
  }

  get avoimetRows() {
    return this.avoimetKoejaksot?.page.totalElements ?? 0
  }

  get muutRows() {
    return this.muutKoejaksot?.page.totalElements ?? 0
  }

  get erikoisalatSorted() {
    return this.erikoisalat?.sort((a, b) => sortByAsc(a.nimi, b.nimi))
  }

  taskIcon(status: string) {
    switch (status) {
      case LomakeTilat.ODOTTAA_HYVAKSYNTAA:
        return ['far', 'clock']
      case LomakeTilat.PALAUTETTU_KORJATTAVAKSI:
        return ['fas', 'undo-alt']
      case LomakeTilat.HYVAKSYTTY:
        return ['fas', 'check-circle']
      case LomakeTilat.ALLEKIRJOITETTU:
        return ['fas', 'check-circle']
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA:
        return ['far', 'check-circle']
      case LomakeTilat.ODOTTAA_ESIMIEHEN_HYVAKSYNTAA:
        return ['far', 'check-circle']
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        return ['far', 'check-circle']
      case LomakeTilat.ODOTTAA_TOISEN_KOULUTTAJAN_HYVAKSYNTAA:
        return ['far', 'check-circle']
    }
  }

  taskClass(status: string) {
    switch (status) {
      case LomakeTilat.ODOTTAA_HYVAKSYNTAA:
        return 'text-warning'
      case LomakeTilat.PALAUTETTU_KORJATTAVAKSI:
        return ''
      case LomakeTilat.HYVAKSYTTY:
        return 'text-success'
      case LomakeTilat.ALLEKIRJOITETTU:
        return 'text-success'
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA:
        return 'text-success'
      case LomakeTilat.ODOTTAA_ESIMIEHEN_HYVAKSYNTAA:
        return 'text-success'
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        return 'text-success'
      case LomakeTilat.ODOTTAA_TOISEN_KOULUTTAJAN_HYVAKSYNTAA:
        return 'text-success'
    }
  }

  taskStatus(status: string) {
    switch (status) {
      case LomakeTilat.ODOTTAA_HYVAKSYNTAA:
        return this.$t('lomake-tila-' + TaskStatus.AVOIN)
      case LomakeTilat.PALAUTETTU_KORJATTAVAKSI:
        return this.$t('lomake-tila-' + TaskStatus.PALAUTETTU)
      case LomakeTilat.HYVAKSYTTY:
        return this.$t('lomake-tila-' + TaskStatus.HYVAKSYTTY)
      case LomakeTilat.ALLEKIRJOITETTU:
        return this.$t('lomake-tila-' + TaskStatus.ALLEKIRJOITETTU)
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA:
        return this.$t('lomake-tila-' + TaskStatus.ODOTTAA_VASTUUHENKILON_ALLEKIRJOITUSTA)
      case LomakeTilat.ODOTTAA_ESIMIEHEN_HYVAKSYNTAA:
        return this.$t('lomake-tila-' + TaskStatus.ODOTTAA_ESIMIEHEN_HYVAKSYNTAA)
      case LomakeTilat.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        return this.$t('lomake-tila-' + TaskStatus.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA)
      case LomakeTilat.ODOTTAA_TOISEN_KOULUTTAJAN_HYVAKSYNTAA:
        return this.$t('lomake-tila-' + TaskStatus.ODOTTAA_TOISEN_KOULUTTAJAN_HYVAKSYNTAA)
    }
  }
}
