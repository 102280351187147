


















































import { Component, Vue } from 'vue-property-decorator'

import { getEtusivuValmistumispyynnot as getEtusivuValmistumispyynnotVastuuhenkilo } from '@/api/vastuuhenkilo'
import { getEtusivuValmistumispyynnot as getEtusivuValmistumispyynnotVirkailija } from '@/api/virkailija'
import ElsaButton from '@/components/button/button.vue'
import BCardSkeleton from '@/components/card/card.vue'
import { ValmistumispyyntoListItem } from '@/types'
import { ValmistumispyynnonTila } from '@/utils/constants'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    BCardSkeleton,
    ElsaButton
  }
})
export default class ValmistumispyynnotCard extends Vue {
  valmistumispyynnot: ValmistumispyyntoListItem[] | null = null
  loading = true

  async mounted() {
    try {
      this.valmistumispyynnot = this.$isVastuuhenkilo()
        ? (await getEtusivuValmistumispyynnotVastuuhenkilo()).data
        : (await getEtusivuValmistumispyynnotVirkailija()).data
    } catch (err) {
      toastFail(this, this.$t('valmistumispyyntojen-hakeminen-epaonnistui'))
    }
    this.loading = false
  }

  get fields() {
    return [
      {
        key: 'erikoistuvanNimi',
        label: this.$t('erikoistuja'),
        sortable: true
      },
      {
        key: 'pvm',
        label: this.$t('pvm'),
        sortable: true
      },
      {
        key: 'tyyppi',
        label: this.$t('vaihe'),
        sortable: true
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        class: 'actions'
      }
    ]
  }

  get rows() {
    return this.valmistumispyynnot?.length ?? 0
  }

  linkComponent(tila: ValmistumispyynnonTila) {
    if (this.$isVirkailija()) {
      return 'valmistumispyynnon-tarkistus'
    } else {
      if (tila === ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_TARKASTUSTA) {
        return 'valmistumispyynnon-arviointi'
      } else {
        return 'valmistumispyynnon-hyvaksynta'
      }
    }
  }

  buttonText(tila: ValmistumispyynnonTila) {
    if (this.$isVirkailija()) {
      return 'tarkista'
    } else {
      if (tila === ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_TARKASTUSTA) {
        return 'tee-arviointi'
      } else {
        return 'hyvaksy'
      }
    }
  }

  taskStatus(status: string) {
    switch (status) {
      case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_TARKASTUSTA:
        return this.$t('valmistumispyynnon-tila.avoimet-asiat-odottaa-osaamisen-arviointia')
      case ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA:
        return this.$t('valmistumispyynnon-tila.avoimet-asiat-odottaa-hyvaksyntaa')
      case ValmistumispyynnonTila.ODOTTAA_ALLEKIRJOITUKSIA:
        return this.$t('valmistumispyynnon-tila.avoimet-asiat-odottaa-allekirjoituksia')
      case ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA:
        return this.$t('valmistumispyynnon-tila.avoimet-asiat-odottaa-virkailijan-tarkastusta')
      case ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN:
        return this.$t('valmistumispyynnon-tila.avoimet-asiat-virkailijan-tarkastus-kesken')
    }
  }
  showButton(tila: ValmistumispyynnonTila) {
    if (this.$isVirkailija())
      return (
        tila === ValmistumispyynnonTila.ODOTTAA_VIRKAILIJAN_TARKASTUSTA ||
        tila === ValmistumispyynnonTila.VIRKAILIJAN_TARKASTUS_KESKEN
      )
    else
      return (
        tila === ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_TARKASTUSTA ||
        tila === ValmistumispyynnonTila.ODOTTAA_VASTUUHENKILON_HYVAKSYNTAA
      )
  }
}
